<template>
  <Card class="allSearchCard">
    <template>
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{cardInfo.surveyNo}}</h3>
          <span class="tag">{{cardInfo.surveyStatus}}</span>
        </div>
        <div
          class="card-info"
          @click="goDetail(cardInfo)"
        >
          <h3 class="name">创建时间：{{cardInfo.createtime}}</h3>
          <span
            class="tag"
            style="color: #DA3738;"
          >勘测单详情<Icon
              class="arrow-icon"
              name="arrow"
            ></Icon></span>
        </div>
        <div>{{cardInfo.exceptionReason}}</div>
      </template>
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{cardInfo.accountName}}</h3>
          <!-- <span class="tag"
                @click="goAccountDetail(cardInfo.eosAccountId)"
                style="color: #DA3738;">客户详情<Icon class="arrow-icon"
                  name="arrow"></Icon></span> -->
        </div>
      </template>
      <template slot="footer">
        <span></span>
        <div>
          <Button
            round
            @click="goAccountDetail(cardInfo.eosAccountId)"
            type="info"
            style="margin-right:10px"
            size="small"
          >客户详情</Button>
          <Button
            round
            type="info"
            size="small"
          >完成任务</Button>
        </div>
      </template>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Toast } from 'vant'
import Card from '_c/card'
import { orgCode } from '@/api/chargequery'
import 'vant/lib/icon/style'
import 'vant/lib/row/style'
import 'vant/lib/col/style'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'CardItem',
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
    }
  },
  components: {
    Card,
    Button,
    Icon
  },
  methods: {
    // 跳转到客户详情
    goAccountDetail (accountId) {
      this.$router.push({
        path: '/customerInfo',
        query: { 'id': accountId }
      })
    },
    goDetail () {
      console.log('==');
      this.$router.push({
        path: '/surveyInfo'
      })
    },
    init () {
      console.log(111)
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      const callphone = cardinfo.qbsAccountPhone
      this.orgCode(callphone)
    },
    // 拨打电话
    async orgCode (callphone) {
      const res = await orgCode({ orgCode: callphone }) //
      if (res.code === '000000') {
        Toast('Calling')
      } else {
        Toast(res.msg)
      }
    },
  },
  mounted () {
    this.init()
  }
}
</script>