<template>
  <App
    left-arrow
    title="勘测跟进"
    @click-right="onClickRight"
    class="allSearch"
  >
    <Tabs
      v-model="tabActive"
      slot="extra"
      @change="tableChange"
    >
      <Tab
        :title="$i.title"
        v-for="$i in tabsData"
        :key="$i.label"
        :name="$i.label"
      >
      </Tab>
    </Tabs>
    <PullRefresh
      @refresh="onRefresh"
      v-model="loading"
    >
      <List
        v-model="loading"
        :immediate-check="false"
        :error.sync="error"
        :finished="finished"
        :finished-text="total === 0 ? 'No data!' : 'No more items.!'"
        error-text="Loading failed. Click to retry"
        @load="Onload"
      >
        <CardItem
          v-for="$items in list"
          :key="$items.id"
          :cardInfo="$items"
          :type="tabActive"
        />
      </List>
    </PullRefresh>
  </App>
</template>

<script>
import { Tab, Tabs, PullRefresh, List, Toast } from 'vant'
import { debounce } from 'lodash-es'
import { tabsData } from './attr.js'
import { breakOffList } from '@/api/follow/index'
import CardItem from './components/card/index'
import 'vant/lib/nav-bar/style'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'surveyFollowUp',
  data () {
    return {
      showResult: false,
      hasDate: true,
      loading: false,
      finished: false,
      error: false,
      radioVal: '1',
      list: [],
      tabActive: this.$route.query.tabName,
      tabsData: [],
      page: 1,
      size: 10,
      total: 0
    }
  },
  components: {
    Tab,
    Tabs,
    CardItem,
    List,
    PullRefresh,
  },
  methods: {
    getData: debounce(function () {
      if (this.finished) return
      switch (this.tabActive) {
        case 'termination':
          this.getBreakOffList()
          break;
      }
    }, 600, { leading: true, trailing: false }),
    // 勘测终止
    async getBreakOffList () {
      try {
        /* Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          loadingType: 'spinner',
          overlay: true
        }) */
        const res = await breakOffList({ 'page': this.page, 'size': this.size, 'currentIdentity': this.$route.query.currentIdentity })
        Toast.clear()
        if (res && res.success) {
          this.total = res.data.total
          const arr = [...this.list, ...res.data.records]
          this.list = this.arrDistinctByProp(arr, 'installOrderNo')
          if (this.list.length === res.data.total) {
            this.finished = true
          } else {
            this.page++
          }
          this.error = false
          this.loading = false
        } else {
          this.error = true
          this.loading = false
          Toast(res.msg)
        }
      } catch (error) {
        Toast.clear()
        console.log(error);
        this.error = true
        this.loading = false
      }
    },
    arrDistinctByProp (arr, prop) {
      return arr.filter((item, index, self) => {
        return self.findIndex(el => el[prop] == item[prop]) === index
      })
    },
    tableChange () {
      this.page = 1
      this.list = []
      this.total = 0
      this.finished = false
      this.loading = false
      this.getData()
    },
    // 下拉刷新
    onRefresh () {
      this.page = 1
      this.finished = false
      this.list = []
      this.total = 0
      this.getData()
    },
    Onload () {
      this.getData()
      this.loading = false
    },
    init () {
      this.page = 1
      this.list = []
      this.total = 0
      this.finished = false
      this.loading = false
      this.tabsData = tabsData
      this.tabActive = this.$route.query.tabName
      this.getData()
    },
    onClickRight () {
      this.showResult = false
    }
  },
  mounted () {
    this.init()
  }
}
</script>